import React from "react";
import { Col, Row } from "antd";
import Layout from "../../../components/Layout/layout";
import SEO from "../../../components/seo";
import ogImage from "../../../images/global_assets/og-image.png";
import { viewports } from "../../../style-vars";
import Image from "../../../components/ImageQuerys/RandomImages";
import CtaPrimary from "../../../components/Buttons/primary-cta";
import CtaSecondary from "../../../components/Buttons/secondary-cta";
import {
  emailValidationRegExp,
  formItemTypes,
  phoneValidationRegExp,
} from "../../../components/GatedAsset/constants";
import GatedForm from "../../../components/CustomGated/GatedForm";

const { mdOrSmaller, smOrSmaller } = viewports;
const formDataToSend = ["firstname", "email", "phone", "company", "locations"];
const subtext =
  "Learn more about how you can overcome labor challenges and improve operational efficiency with modern technology.";

const data = [
  {
    itemType: formItemTypes.INPUT,
    itemSubType: "text",
    label: "Your Name",
    name: "firstname",
    rules: [
      {
        required: true,
        message: "Please enter your name",
        min: 2,
      },
    ],
    placeholder: "e.g. John Doe",
    visible: true,
  },
  {
    itemType: formItemTypes.INPUT,
    itemSubType: "email",
    label: "Email",
    name: "email",
    rules: [
      {
        required: true,
        message: "Please enter a valid email",
        pattern: emailValidationRegExp,
      },
    ],
    placeholder: "e.g. john_doe@mybusiness.com",
    visible: true,
  },
  {
    itemType: formItemTypes.INPUT,
    itemSubType: "text",
    label: "Business Name",
    name: "company",
    rules: [
      {
        required: true,
        message: "Please enter your business name",
        min: 3,
      },
    ],
    placeholder: "e.g. MyBusiness Inc. ",
    visible: true,
  },
  {
    itemType: formItemTypes.INPUT,
    itemSubType: "tel",
    label: "Phone Number",
    name: "phone",
    rules: [
      {
        required: true,
        message: "Please enter your phone",
        pattern: phoneValidationRegExp,
      },
    ],
    placeholder: "e.g. (555) 555-1243 ",
    visible: true,
  },
  {
    itemType: formItemTypes.DROPDOWN,
    itemSubType: null,
    label: "Number of restaurant location",
    name: "locations",
    options: [
      {
        title: "1-10",
        value: "1-10",
      },
      {
        title: "more than 10",
        value: "10+",
      },
    ],
    rules: [
      {
        required: true,
        message: "Select how many location you have",
      },
    ],
    placeholder: "e.g. 1-10",
    visible: true,
  },
];

const NRA = () => {
  return (
    <Layout
      isLanding
      relMenuHeight={68}
      hideBanner
      phoneNumber="+18775594225"
      phoneText="(877) 559-4225"
      className="non-fixed-width"
    >
      <SEO
        title="SpotOn | Appetize | National Restaurant Association Show"
        description="Talk to a member of our team to learn how we can help your business with the technology showcased in the National Restaurant Association Show webinar “How Modern Tech Can Alleviate Labor Challenges and Improve Operational Efficiency.”"
        image={`https://spoton.com/${ogImage}`}
      />
      <section style={{ maxWidth: "1440px", margin: "auto" }}>
        <section className="nra-hero">
          <Image imageName="circle.png" className="circle-left" />
          <h1 className="text-center nra-title">
            How Modern Tech Can Alleviate Labor Challenges and Improve
            Operational Efficiency<span className="blue_dot">.</span>
          </h1>
          <div className="form-circle">
            <div className="nra-form">
              <GatedForm
                pageSubtext={subtext}
                formTitle="Thank you for attending our webinar!"
                formId="445756bd-d3f6-4a76-89f0-49bada603759"
                formItems={data}
                formDataToSend={formDataToSend}
                thankYouPath="/enterprise/nra/thank-you"
                btnText="Learn more"
              />
            </div>
          </div>
          <Image imageName="circle.png" className="circle-right" />
        </section>
        <section className="mid-text">
          <Row gutter={[32, 32]}>
            <Col lg={12} sm={24}>
              <h2>
                SpotOn &amp; Appetize—better together
                <span className="blue_dot">.</span>
              </h2>
              <CtaPrimary ctaTitle="Learn more" target="/demo" />
            </Col>
            <Col lg={12} sm={24}>
              <p>
                SpotOn is one of the fastest-growing software and payments
                companies with comprehensive, cloud-based technology for small,
                midsize and enterprise businesses in the restaurant, retail,
                sports, and entertainment space. In September 2021, SpotOn
                acquired Appetize, bringing together two forward-thinking
                companies with industry-leading customer service and a shared
                mission of delivering smarter technology to help businesses
                connect with their customers.
              </p>
              <CtaSecondary ctaTitle="Learn more" target="/enterprise" />
            </Col>
          </Row>
        </section>
        <section className="prefooter">
          <Image imageName="spoton-app.png" className="nra-sp" />
          <Image imageName="end-two-end.png" className="nra-ete" />
        </section>
      </section>
      <style jsx global>{`
        .prefooter {
          background: #090d12;
          padding: 50px 100px;
          width: 100%;
        }
        .nra-hero {
          background: #090d12;
          min-height: 800px;
          padding: 150px 200px;
          width: 100%;
          position: relative;
        }
        .circle-left {
          position: absolute;
          left: -30%;
          top: 28%;
          width: 40%;
        }
        .circle-right {
          position: absolute;
          right: -20%;
          top: 0%;
          width: 30%;
        }
        .form-circle {
          width: 700px;
          height: 700px;
          border-radius: 700px;
          background: #1769ff;
          bottom: -300px;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
        }
        .nra-title {
          color: #fff;
          font-size: 42px;
          font-weight: 800;
          margin-top: 40px;
        }
        .nra-form {
          background: #fff;
          border-radius: 16px;
          margin-top: 64px;
          box-shadow: 0px 21.2459px 65.0656px rgba(1, 44, 109, 0.16);
        }
        .mid-text {
          padding: 400px 200px 150px 200px;
        }
        .mid-text h2 {
          font-size: 42px;
          font-weight: 800;
          margin-bottom: 24px;
        }
        .mid-text p {
          font-size: 16px;
          font-weight: 400;
          margin-bottom: 24px;
          color: #445469;
        }
        .nra-sp {
          width: 200px;
          margin: 0 auto;
        }
        .nra-ete {
          width: 70%;
          margin: 120px auto 0 auto;
        }
        @media screen and (max-width: 1024px) {
          .nra-hero {
            padding: 100px 100px;
            width: 100%;
            position: relative;
            min-height: auto;
          }
          .form-circle {
            bottom: auto;
            position: relative;
            margin: 32px auto;
            transform: translateX(-60%);
          }
          .nra-form {
            transform: translateY(32px);
            margin-top: 4px;
          }
          .mid-text {
            padding: 64px 100px 64px 100px;
          }
          .prefooter {
            margin-bottom: 32px;
          }
        }
        @media ${mdOrSmaller} {
          .nra-hero {
            padding: 100px 64px 200px 64px;
            width: 100%;
            position: relative;
            min-height: auto;
          }
          .form-circle {
            transform: translateX(-50%);
          }
          .prefooter {
            padding: 64px 100px;
          }
          .nra-ete {
            width: 100%;
          }
        }
        @media ${smOrSmaller} {
          .nra-hero {
            padding: 64px 16px;
          }
          .nra-form {
            margin-top: 4px;
            max-width: 100%;
          }
          .prefooter {
            padding: 64px 16px;
          }
          .mid-text {
            padding: 64px 16px;
          }
          .nra-ete {
            width: 100%;
            margin: 120px auto 0 auto;
          }
          .form-circle {
            width: 100%;
            height: auto;
            border-radius: 32px;
            background: #1769ff;
            position: relative;
            left: auto;
            transform: translateX(0);
          }
          .nra-title {
            font-size: 38px;
          }
          .mid-text h2 {
            font-size: 32px;
          }
          .nra-form {
            transform: translateY(0);
            padding: 12px;
          }
        }
      `}</style>
    </Layout>
  );
};

export default NRA;
